// src/questiontext.js

export const questionsSpectrum1 = [
    "I have no interest in falling in love with someone besides my partner, even if it didn't reduce my love towards my partner.",
    "I am not interested in the idea of having oral sex with someone besides my partner.",
    "It's not enjoyable to flirt with people besides my partner, even if my partner were fine with it",
    "I wouldn't like sexting with someone else I met online",
    "I have no interest in having a threesome",
    "I have no interest in having kids with someone else (even if I can still have kids with my partner, if we both wanted to)",
    "I'd like to date someone else seriously, bringing them over, and including them in my partner's life",   
    "If the opportunity came up, I'd be down to go on a three-day romantic vacation with an attractive new person.",  
    "I would like to have a casual hookup with someone I'm not really planning to see again, given I'll really enjoy the sex",
    "I'm interested in the idea of sloppy makeouts with a stranger (not my partner) at a wild party",
    "My natural inclination is to have sexual fantasies about someone else besides my partner",
    "I'd like to keep in contact with my exes",
    "To be honest, even if my sex life with my partner was vibrant and healthy, I think I'd still be interested in having sex with a new, attractive person",
    // ... add the rest of your questions for Spectrum 1
  ];
  
  export const questionsSpectrum2 = [
    "I'd be uncomfortable being in a relationship with someone who goes on a three-day romantic vacation with someone else",
    "I don't think I could date someone who has oral sex with someone else",
    "I'd be inclined to break up with someone if they had a casual hookup with someone else (they aren't planning to see again)",
    "I am not okay with my partner sexting people they'd met online",
    "I'd be uncomfortable being in a relationship with someone who has sexual fantasies about someone else",
    "I'd be uncomfortable being in a relationship with someone who wanted to go to a swinger's club with me",
    "I'd be uncomfortable if my partner attended a sex-positive workshop",
    "I'd feel good about being in a relationship with someone who seriously dates someone else, brings them over, includes them in our life",
    "I wouldn't mind being in a relationship with someone who falls in love with someone else (even if this doesn't reduce their love towards me)",
    "I wouldn't mind being in a relationship with someone who has anal sex with someone else",
    "It'd be no big deal to date someone who flirts with other people",
    "I'd happily date someone who frequently likes or comments on another attractive person's suggestive social media posts",
    "It's no big deal if my partner keeps in contact with their exes",

    // ... add the rest of your questions for Spectrum 2
  ];
  