import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Question from "./Question";
import { questionsSpectrum1, questionsSpectrum2 } from "./questiontext";
 
function App() {
  const totalQuestions = 26;
  const [answers, setAnswers] = useState(Array(totalQuestions).fill(null));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const resultCanvasRef = useRef(null);
  const [isFrontPage, setIsFrontPage] = useState(true);
 
  console.log(answers);
 
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }
 
  useEffect(() => {
    const combinedQuestions = [...questionsSpectrum1, ...questionsSpectrum2];
    setQuestions(shuffle(combinedQuestions));
  }, []);
 
  const handleStartQuiz = () => {
    setIsFrontPage(false);
  };
 
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
 
  const handleAnswer = (option) => {
    console.log(
      `Original score for question ${currentQuestionIndex}: ${option}`
    );
    let adjustedOption = option;
    const question = questions[currentQuestionIndex];
    if (needsFlipping(question)) {
      adjustedOption = -option;
      console.log(
        `Score flipped for question ${currentQuestionIndex}: ${adjustedOption}`
      );
    } else {
      console.log(
        `Score unchanged for question ${currentQuestionIndex}: ${adjustedOption}`
      );
    }
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = adjustedOption;
    setAnswers(newAnswers);
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResult(true);
    }
  };
  const calculateAverageScores = () => {
    let sumSpectrum1 = 0;
    let sumSpectrum2 = 0;
  
    for (let i = 0; i < 26; i++) {
      let currQuestion = questions[i];
  
      if (questionsSpectrum1.includes(currQuestion)) {
        // Inverting the sign of the Spectrum 1 score
        sumSpectrum1 -= answers[i]; 
      } else {
        sumSpectrum2 += answers[i];
      }
    }
  
    const countSpectrum1 = 13;
    const countSpectrum2 = 13;
  
    const spectrum1Average = sumSpectrum1 / countSpectrum1;
    const spectrum2Average = sumSpectrum2 / countSpectrum2;
  
    return { spectrum1: spectrum1Average, spectrum2: spectrum2Average };
  };
  
  const needsFlipping = (question) => {
    const flipQuestions = [
      // List the exact questions
 
      "I have no interest in falling in love with someone besides my partner, even if it didn't reduce my love towards my partner.",
      "I am not interested in the idea of having oral sex with someone besides my partner.",
 
      "It's not enjoyable to flirt with people besides my partner, even if my partner were fine with it",
      "I wouldn't like sexting with someone else I met online",
      "I have no interest in having a threesome",
      "I have no interest in having kids with someone else (in addition to having kids with my partner, if they also wanted them)",
      "I'd be uncomfortable being in a relationship with someone who goes on a three-day romantic vacation with someone else",
      "I don't think I could date someone who has oral sex with someone else",
      "I'd be inclined to break up with someone if they had a casual hookup with someone else (they aren't planning to see again)",
      "I am not okay with my partner sexting people they'd met online",
      "I'd be uncomfortable being in a relationship with someone who has sexual fantasies about someone else",
      "I'd be uncomfortable being in a relationship with someone who wanted to go to a swinger's club with me",
      "I'd be uncomfortable if my partner attended a sex-positive workshop",
    ];
    return flipQuestions.includes(question);
  };
  const drawResult = () => {
    if (!resultCanvasRef.current) {
      console.log("Canvas not ready");
      return;
    }
    const { spectrum1, spectrum2 } = calculateAverageScores();
    console.log("Raw scores:", { spectrum1, spectrum2 });
    const canvas = resultCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    // Assuming the score range is -3 to 3
    const scoreRange = 3;
    let xPosition = ((spectrum1 + scoreRange) / (scoreRange * 2)) * width;
    let yPosition = ((scoreRange - spectrum2) / (scoreRange * 2)) * height;
    // Adjust yPosition to canvas coordinates (0 at top)
    yPosition = height - yPosition;
    // Ensure that the dot is placed in the correct quadrant
    xPosition = Math.max(0, Math.min(xPosition, width));
    yPosition = Math.max(0, Math.min(yPosition, height));
    console.log("Canvas positions:", { xPosition, yPosition });
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, 0, 0, width, height);
      ctx.fillStyle = "red";
      ctx.beginPath();
      ctx.arc(xPosition, yPosition, 12, 0, Math.PI * 2);
      ctx.fill();
    };
    image.src = "https://i.imgur.com/I3hLJwP.jpg";
  };
  useEffect(() => {
    if (showResult && resultCanvasRef.current) {
      drawResult();
    }
  }, [showResult]);
  return (
    <div className={`App ${showResult ? "final-results-container" : ""}`}>
      {showResult ? (
        <>
          <h2>Your Nonmonogamy Compass Results</h2>
          <h3>relationship-compass.com</h3>
          <canvas
            ref={resultCanvasRef}
            width="400"
            height="400"
            style={{ border: "0px solid black" }}
          />
          <div className="links-container">
            <br />
            ‏‏‎ ‎
            <br />
            ‏‏‎ ‎
            <a
              href="https://knowingless.com/survey/"
              target="_blank"
              rel="noopener noreferrer"
            >
              More Surveys
            </a>
            <br />
            <a
              href="https://aella.substack.com/p/good-at-sex-the-marketplace-of-sex"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide To Hooking Women On Your Penis Heroin
            </a>
            <br />
            <a
              href="https://twitter.com/Aella_Girl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
        </>
      ) : isFrontPage ? (
        <div className="front-page-container">
          <h1>The (non?)monogamy Compass Quiz</h1>
          <p>         
             If you don't have a partner, answer as though you have a partner.
             <br/>‏‏‎ ‎<br/>
             
            <b>For best results,</b> answer as though you're in a world where:
            <br />
            1. Your partner would be deeply okay with your actions, no fallout.
            <br />
            2. And where your partner is honest with you about what they do.
          </p>

          <button onClick={handleStartQuiz}>
            Got it, everyone is honest and chill
          </button>
        </div>
      ) : (
        <div className="quiz-container">
          {currentQuestionIndex > 0 && (
            <button className="back-button" onClick={handlePreviousQuestion}>
              Back
            </button>
          )}
          <Question
            questionText={questions[currentQuestionIndex]}
            questionIndex={currentQuestionIndex}
            currentAnswer={answers[currentQuestionIndex]}
            onAnswer={handleAnswer}
          />
        </div>
      )}
    </div>
  );
}
export default App;
 