import React from 'react';
import './Question.css';

function Question({ questionText, onAnswer, questionIndex, currentAnswer }) {
  const options = [-3, -2, -1, 0, 1, 2, 3];
  const labels = {
    "-3": "Strong disagree",
    "-2": "Disagree",
    "-1": "Slight disagree",
    "0": "Neutral",
    "1": "Slight agree",
    "2": "Agree",
    "3": "Strong agree"
  };

  return (
    <div className="question-container">
      <p>{questionText}</p> 
      <div className="options-container">
        {options.map((option) => (
          <div className="option" key={option}>
            <input
              type="radio"
              name={`question-${questionIndex}`}
              id={`option-${questionIndex}-${option}`}
              value={option}
              checked={currentAnswer === option}
              onChange={() => onAnswer(option)}
            />
            <label htmlFor={`option-${questionIndex}-${option}`} className="label">{labels[option] || ''}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Question;
